




















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import IconCalendar2 from '@/app/ui/assets/icon_calendar_2.vue';
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import manageCourierController from '@/app/ui/controllers/ManageCourierController'
import controller from '@/app/ui/controllers/ManageCaptainController'
import { ManageCourierAdjustSaldo } from '@/domain/entities/ManageCourier'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { Utils } from '@/app/infrastructures/misc'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface Options {
  label: string
  value: string | number
}

interface IForm {
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  courier: Options
  letterCodes: Options[]
  skStartDate: string
  skEndDate: string
  status: string
}

interface ManageCaptainDetail {
  id: number
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  courierId: number
  letterCodes: string[]
  skStartDate: string
  skEndDate: string
  status: string
}

@Component({
  components: {
    DropdownSelect,
    DateTimePicker,
    MultiselectV2,
    Loading,
    TextInput,
    Button,
    ModalSuccess,
    IconCalendar2,
  },
})

export default class DetailManageCaptain extends Vue {
  controller = controller
  mcController = manageCourierController
  routeController = routeController
  isPeriodComplete = false

  courierList: Array<Options> = []
  letterCodeOptions: Array<Options> = []
  captainOriginList: Array<string> = []

  form: IForm = {
    createdAt: '-',
    createdBy: '-',
    updatedAt: '-',
    updatedBy: '-',
    courier: this.courierList[0],
    letterCodes: [],
    skStartDate: '',
    skEndDate: '',
    status: '-',
  }

  parameters = {
    page: 1,
    perPage: 5,
    q: '',
    sortBy: 'ASC',
    driverType: 'KVP',
    courierStatus: ''
  }

  created(): void {
    this.fetchData()
  }

  get courierParams(): Record<string, string | number> {
    return this.parameters;
  }

  private fetchData(): void {
    controller.getDetail(Number(this.$route.params.id))
  }

  private fetchCourier(): void {
    this.mcController.getManageCourierListAdjustSaldo(this.courierParams)
  }

  private fetchOriginList(): void {
    this.routeController.getLetterCodes()
  }

  private onEdit(id: number): void {
    this.$router.push(`/payroll/manage-captain/edit/${id}`)
  }

  private formatDateTime(dateTime?: string): string {
    if (!dateTime) return '-';
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(dateTime, 'DD MMMM, HH:mm Z')
    )
  }

  @Watch('controller.dataManageCaptain')
  setDataManageCaptainDetail(data: ManageCaptainDetail): void {
    this.form = {
      createdAt: this.formatDateTime(data.createdAt),
      createdBy: data.createdBy || '-',
      updatedAt: this.formatDateTime(data.updatedAt),
      updatedBy: data.updatedBy || '-',
      courier: this.courierList[-1],
      letterCodes: [],
      skStartDate: dayjs(data.skStartDate).locale(dayjsID).format('DD MMMM YYYY'),
      skEndDate: dayjs(data.skEndDate).locale(dayjsID).format('DD MMMM YYYY'),
      status: data.status || '-',
    }
    this.parameters.q = data.courierId ? String(data.courierId) : ''
    this.captainOriginList = data.letterCodes || []
    this.isPeriodComplete = (
      dayjs().isAfter(dayjs(data.skEndDate)) && !dayjs().isSame(dayjs(data.skEndDate), 'day')
    )

    this.fetchCourier()
    this.fetchOriginList()
  }

  @Watch('routeController.letterCodes')
  setOriginData(data: LetterCode[]): void {
    let res: Array<Options> = []
    this.letterCodeOptions = data.map((route) => {
      return {
        label: <string>route.lc,
        value: <string>route.lc
      }
    })

    this.captainOriginList.forEach((origin) => {
      const optRes = this.letterCodeOptions.find((opt) => opt.value === origin)

      if (optRes) {
        res.push(optRes)
      }
    })

    this.form.letterCodes = res
  }

  @Watch('mcController.manageCourierAdjustSaldoData')
  setManageCourierData(data: ManageCourierAdjustSaldo[]): void {
    let result: Array<Options> = []

    data.forEach((courier: ManageCourierAdjustSaldo) => {
      const label = `[${courier.courierId}] ${courier.fullName} (${courier.announcementCourierType}) ${courier.phoneNumber} • ${courier.partnerName}`
      const value = courier.courierId

      if (label && value) {
        result.push({
          label: label,
          value: value
        })
      }
    })

    this.courierList = result

    if (this.courierList.length !== 0) {
      const courier = this.courierList.find((courier) => courier.value === controller.dataManageCaptain.courierId)
      if (courier) {
        this.form.courier = courier
      }
    }
  }
}

